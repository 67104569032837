module.exports = {
  //-- SITE SETTINGS -----
  author: "Jasper Anders",
  institutionResponsible: {
    name: "Jasper Anders",
    contact: "@_asperanders",
    contactChannel: "Twitter",
  },
  siteTitle: "Marginalien",
  siteShortTitle: "Marginalien", // Used as logo text in header, footer, and splash scree
  siteDescription: "Randnotizen zu allem Möglichen.",
  siteUrl: "https://marginalien.jasperanders.xyz",
  siteLanguage: "de",
  siteIcon: "content/globals/assets/favicon.png", // Relative to gatsby-config fil
  seoTitleSuffix: "Marginalien", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}
  defaultLanguage: "de",
  supportedLanguages: ["de"],
  //-- NAVIGATION SETTINGS -----
  footerLinks: [
    // {
    //   name: "Privacy Statement",
    //   to: "/privacy",
    // },
    {
      name: "Imprint",
      to: "/imprint",
    },
  ],
  headerLinks: [
    {
      name: "Docs",
      to: "/docs",
    },
  ],
};
