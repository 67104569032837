import { extendTheme } from "@chakra-ui/react";
import "@fontsource/noto-sans/400.css"; // Weight 500 with all styles included.
import "@fontsource/fira-code/600.css"; // Weight 500 with all styles included.
import "@fontsource/fira-code/400.css"; // Weight 500 with all styles included.
import "@fontsource/dosis/700.css"; // Weight 500 with all styles included.
import "@fontsource/arvo/400.css"; // Weight 500 with all styles included.
import { Heading, Text, Image, Link, Table } from "./components";

const theme = extendTheme({
  fonts: {
    logo: "arvo",
    heading: "Fira Code",
    mono: "Fira Code",
    body: "Noto Sans",
  },
  colors: {
    primary: 
    "#2c3e50",
    accent:
    "#e63946",
    secondaryLight: "#B7EDE0",
    contrast: "#BEF7EE",
    contrastDark: "#5C7874",
    contrastLight: "#DEFFF9",
    backgroundLight: "#F2F6FE",
    black: "#444444",
  },
  borders: {
    base: "0.5rem solid #333",
  },
  components: {
    Heading,
    Text,
    Link,
    Table,
    Image,
  },
});

export default theme;
