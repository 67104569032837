import { Image } from "@chakra-ui/react";
import React from "react";


const BlogImage = ({...props}) => {
  return (
    <Image width={'100%'} {...props}/>
  );
};

export default BlogImage;
